<!--
 * @Author: your name
 * @Date: 2021-06-30 07:58:39
 * @LastEditTime: 2021-07-27 08:19:33
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /vue-uscenter/src/views/Reg.vue
-->
<template>
  <v-app>
    <v-container app fluid>
      <Vcode
        :show="VcodeBox.show"
        :canvasWidth="VcodeBox.width"
        @success="onSuccess"
        @close="onClose"
      />
      <div class="mb-0 d-flex justify-center">
        <v-card
          id="loginBox"
          max-width="420"
          dark
          width="100%"
          height="65"
          class="rounded-b-0"
        >
          <v-img height="65px" src="./images/bg-003.png">
            <v-card-title primary-title> 用户注册 </v-card-title>
          </v-img>
        </v-card>
      </div>

      <div v-if="win < 1" class="mb-2 d-flex justify-center">
        <v-card max-width="420" width="100%" class="mb-2" tile outlined>
          <v-card-title class="subtitle-1"> 注册须知 </v-card-title>
          <v-card-text style="font-size: 18px; line-height: 150%">
            1. 使用实名注册，需要录入身份证号码；<br />
            2. 教师、家长、学生均可以注册；<br />
            3. 家长注册允许关联N个学生，无需重复注册；<br />
            4. 同时是教师、家长身份的，无需重复注册。<br />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="teal darken-3" dark block @click="newUser"
              >我知道了</v-btn
            >
          </v-card-actions>
        </v-card>
      </div>

      <div v-if="win == 1" class="mb-2 d-flex justify-center">
        <v-card tile outlined max-width="420" width="100%">
          <v-card-title> 注册手机号码 </v-card-title>
          <v-form ref="form">
            <v-card-text class="px-5 pt-5">
              <v-text-field
                prepend-inner-icon="mdi-cellphone"
                v-model="mobile"
                :counter="11"
                style="font-size: 25px"
                :rules="mobileRules"
                label="手机号码"
                required
                v-on:input="keyInput"
                :disabled="dialogCodeDisabled"
                outlined
              >
              </v-text-field>
            </v-card-text>
            <v-card-text v-if="otp" class="text-center">
              {{ sendText }}
              <v-otp-input
                v-model="mobileCode"
                @finish="postMobile"
                length="4"
                style="width: 210px; margin: 0px auto"
              ></v-otp-input>
            </v-card-text>
          </v-form>
        </v-card>
      </div>
      <div v-if="win == 2" class="mb-2 d-flex justify-center">
        <validation-observer ref="observer" v-slot="{ invalid }">
          <v-card tile outlined max-width="420" width="100%">
            <v-card-title primary-title class="subtitle-1">
              <v-icon class="mr-2"
                >mdi-card-account-details-star-outline</v-icon
              >
              注册信息设定
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="subtitle-1">
              <p>注意：</p>
              <p>
                1.家长请使用本人身份证注册，注册成功后允许添加多名子女，扮演学生参于学校及班级教学活动；
              </p>
              <p>
                2.用户具有教师、家长双重身份时，以教师身份注册后，允许作为家长添加多名子女。
              </p>
              <p>3.学生年龄太小的，家长可以帮助孩子进行注册。</p>
            </v-card-text>

            <v-card-text class="px-7">
              <validation-provider
                name="登录ID"
                v-slot="{ errors }"
                rules="required"
              >
                <v-text-field
                  v-model="usinfo.name"
                  :error-messages="errors"
                  label="我的真实姓名"
                  prepend-inner-icon="mdi-account"
                  hint="家长身份注册的，请使用自己的真实姓名"
                  outlined
                ></v-text-field>
              </validation-provider>
              <validation-provider
                name="我的身份证号码"
                v-slot="{ errors }"
                rules="required|min:8"
              >
                <v-text-field
                  v-model="usinfo.idcard"
                  :error-messages="errors"
                  label="我的身份证号码"
                  prepend-inner-icon="mdi-card-account-details-outline"
                  hint="家长身份注册的，请使用自己的身份证号码"
                  counter
                  outlined
                ></v-text-field>
              </validation-provider>
            </v-card-text>
            <v-card-text class="px-7">
              <validation-provider
                name="设定密码"
                v-slot="{ errors }"
                rules="required|min:6|max:18|password|confirmed:usinfo.passwd2"
              >
                <v-text-field
                  v-model="usinfo.passwd"
                  label="设定密码"
                  prepend-inner-icon="mdi-lock"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :error-messages="errors"
                  :type="show1 ? 'text' : 'password'"
                  hint="密码最少6个字符"
                  counter
                  @click:append="show1 = !show1"
                  outlined
                ></v-text-field>
              </validation-provider>
              <validation-provider
                name="确认密码"
                v-slot="{ errors }"
                rules="required|min:6|max:18|password"
                vid="usinfo.passwd2"
              >
                <v-text-field
                  v-model="usinfo.passwd2"
                  label="确认密码"
                  :type="show2 ? 'text' : 'password'"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  prepend-inner-icon="mdi-lock-outline"
                  @click:append="show2 = !show2"
                  @input="passwdCheck"
                  counter
                  :error-messages="errors"
                  outlined
                ></v-text-field>
              </validation-provider>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pa-4">
              <v-spacer></v-spacer>
              <v-btn
                color="teal darken-3"
                @click="formNewUser"
                :disabled="invalid"
                dark
              >
                <v-icon class="mr-2">mdi-check</v-icon>
                确定提交
              </v-btn>
            </v-card-actions>
          </v-card>
        </validation-observer>
      </div>
      <div v-if="win == 3" class="mb-2 d-flex justify-center">
        <v-card tile outlined max-width="420" width="100%">
          <v-card-title> 注册完成 </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="teal darken-3"
              dark
              @click="$router.push({ path: '/user' })"
            >
              <v-icon class="mr-2">mdi-shield-account-outline</v-icon>
              登录社区
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-container>
    <v-snackbar v-model="snackbar.state" centered color="grey darken-3">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbar.state = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>
<script>
//import VueSimpleVerify from "vue-simple-verify";
//import "../../node_modules/vue-simple-verify/dist/vue-simple-verify.css";
import Vcode from "vue-puzzle-vcode";
export default {
  components: {
    Vcode,
    //VueSimpleVerify,
  },
  data() {
    return {
      win: 0,
      mobile: "",
      tokenString: "",
      sopenCheckcode: {},
      mobileDisabled: false,
      mobileRules: [
        (v) => !!v || "不能为空",
        (v) => (v && v.length == 11) || "手机号码错误",
      ],
      mobileCode: "",
      mobileCodeRules: [
        (v) => !!v || "不能为空",
        (v) => (v && v.length == 4) || "验证码为4个字符",
      ],
      passwdTest: [],
      sendIcon: "mdi-send",
      sendText: "发送验证码",
      verifyPass: false,
      verifyDisabled: false,
      verifyWidth: "",
      dialogCodeDisabled: false,
      postDisabled: true,
      otp: false,
      snackbar: {
        state: false,
        text: "text",
      },
      usinfo: {
        name: "",
        idcard: "",
        passwd: "",
        passwd2: "",
      },
      error: {
        name: "",
        idcard: "",
        passwd2: "",
      },
      show1: false,
      show2: false,
      rules: {
        required: (value) => !!value || "不允许为空.",
        min: (v) => v.length >= 6 || "密码最少需要6个字符",
      },
      usCookie: "",
      VcodeBox: {
        show: false,
        width: 310,
      },
      weChatOpenid: "",
      options: {},
    };
  },
  mounted() {
    this.weChatOpenid = sessionStorage.getItem("weChatOpenid");
    let query = this.$route.query;
    this.win = query.w != undefined ? parseInt(query.w) : 0;
    this.sopenCheck();
    this.enToken();
  },
  methods: {
    onSuccess() {
      this.VcodeBox.show = false; // 通过验证后，需要手动关闭模态框
      this.sendCode();
    },
    onClose() {
      this.VcodeBox.show = false;
    },
    onResize() {
      let loginBox = document.getElementById("loginBox");
      this.verifyWidth = loginBox.clientWidth - 45;
    },
    sopenCheck() {
      this.sopenCheckcode = sessionStorage.getItem("sopenCheckcode");
      this.usCookie = sessionStorage.getItem("usCookie");
    },
    enToken() {
      let tokenString = sessionStorage.getItem("tokenString");
      if (tokenString != "undefined" && tokenString != null) {
        this.tokenString = tokenString;
      } else {
        let data = {
          module: "user",
          title: "enToken",
        };
        let that = this;
        this.$sopen.requestUscenterApi(data).then(function (res) {
          tokenString = res.data.user;
          sessionStorage.setItem("tokenString", tokenString);
          that.tokenString = tokenString;
        });
      }
    },
    newUser() {
      this.$router.push({ path: "/reg?w=1" });
      this.win = 1;
    },
    keyInput() {
      if (this.mobile != null && this.mobile.length == 11) {
        this.VcodeBox.show = true;
      } else {
        this.VcodeBox.show = false;
      }
    },
    sendTime() {
      let timeOut = 30;
      let that = this;
      let d = new Date();
      let t = d.getTime();
      let sendTime = sessionStorage.getItem("sendTime");

      if (sendTime != "NaN") {
        sendTime = parseInt(sendTime);
      }

      if (isNaN(sendTime)) {
        this.dialogCodeDisabled = true;
        let v = t + timeOut * 1000;
        sessionStorage.setItem("sendTime", v);
        this.sendBtn = true;
        this.verifyPass = false;
        this.verifyDisabled = false;
        this.sendTime();
      } else if (parseInt(t) > sendTime) {
        //this.dialogCode = false;
        this.dialogCodeDisabled = false;
        this.sendText = "发送验证码";
        this.verifyDisabled = true;
        sessionStorage.removeItem("sendTime");
      } else {
        this.sendBtn = true;
        let no = Math.ceil((sendTime - t) / 1000);
        this.sendText = "接收中(" + no + ")...";
        setTimeout(function () {
          that.sendTime();
        }, 1000);
        return false;
      }
    },
    sendCode() {
      sessionStorage.setItem("mobile", this.mobile);
      let data = {
        module: "user",
        title: "checkus",
        data: {
          mobile: this.mobile,
          tokenString: this.tokenString,
        },
      };
      let that = this;
      this.$sopen.requestUscenterApi(data).then(function (res) {
        if (res.data.user.errcode > 0) {
          that.snackbar = {
            state: true,
            text: res.data.user.errmsg,
          };
        } else {
          that.dialogCodeDisabled = true;
          that.otp = true;
          that.sendTime();
        }
      });
    },
    dialogCodeClose() {
      sessionStorage.getItem("sendTime");
      this.dialogCode = false;
    },
    editMobile() {
      this.editWindow = true;
    },
    postMobile() {
      let mobile = sessionStorage.getItem("mobile");
      let data = {
        module: "user",
        title: "checkcode",
        data: {
          mobile: mobile,
          tokenString: this.tokenString,
          code: this.mobileCode,
          type: "user",
        },
      };
      let that = this;
      this.$sopen.requestUscenterApi(data).then(function (res) {
        if (res.data.user.errcode == 0) {
          let data = res.data.user.data;
          that.win = 2;
          sessionStorage.setItem("sopenCheckcode", JSON.stringify(data));
        } else {
          that.snackbar = {
            state: true,
            text: res.data.user.errmsg,
          };
          that.mobileCode = "";
        }
      });
    },
    passwdCheck() {
      if (this.usinfo.passwd != this.usinfo.passwd2) {
        this.error.passwd2 = "确认密码与设定密码必须相同";
      } else {
        this.error.passwd2 = "";
      }
    },
    formNewUser() {
      for (let x in this.usinfo) {
        if (this.usinfo[x].length < 1) {
          this.snackbar = {
            state: true,
            text: "请填充完整信息",
          };
          return false;
        }
      }
      for (let x in this.error) {
        if (this.error[x].length > 0) {
          this.snackbar = {
            state: true,
            text: this.error[x],
          };
          return false;
        }
      }
      let sopenCheckcode = sessionStorage.getItem("sopenCheckcode");
      let data = {
        module: "user",
        title: "reg2",
        data: {
          tokenString: this.tokenString,
          checkcode: JSON.parse(sopenCheckcode),
          openid:
            this.weChatOpenid != undefined ? JSON.parse(this.weChatOpenid) : {},
          name: this.usinfo.name,
          idcard: this.usinfo.idcard,
          passwd: this.usinfo.passwd,
        },
      };
      this.options = data;
      let that = this;
      this.$sopen.requestUscenterApi(data).then(function (res) {
        if (res.data.user.errcode > 0) {
          that.snackbar = {
            state: true,
            text: res.data.user.errmsg,
          };
        } else if (res.data.user.errcode == 0) {
          that.usCookie = res.data.user.cookie;
          that.$sopen.setCookie(res.data.user.cookie);
          that.win = 4;
        } else {
          that.snackbar = {
            state: true,
            text: "未知错误",
          };
        }
      });
    },
  },
  watch: {
    $route(newval) {
      this.win = newval.query.w != undefined ? parseInt(newval.query.w) : 0;
    },
    sopenCheckcode(val) {
      if (val && Object.keys(val).length > 0) {
        this.win = 2;
      }
    },
    win(x) {
      if (x == 1) this.onResize();
    },
    usCookie(val) {
      if (val && val.length > 0) {
        this.win = 3;
        sessionStorage.removeItem("tokenString");
        sessionStorage.removeItem("sopenCheckcode");
      }
    },
  },
};
</script>